const axios = require('axios')
const $http = axios.create({
    baseURL : process.env.VUE_APP_BASE_URL
})

const state = {
    videos:[],
    video:{},
	ord:null
}

const getters = {
    fetchVideos : (state) => state.videos,
	fetchVideo: (state) => state.video,
	fetchOrd: (state) => state.ord	
}

const actions = {
    loadVideos({commit}, {filter, token}){
		const query_string = new URLSearchParams(filter).toString();
		return $http.get(`video?${query_string}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(response => {
			commit('SET_VIDEOS', response.data.data)
		})
	},
	loadVideo({ commit }, { id, token }) {
		return $http.get(`video/${id}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_VIDEO', response.data.data)
		})
	},
	createNewVideo({commit}, {video, token}){
		return $http.post('video', video, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('ADD_VIDEO', response.data.data)
		})
	},
	updateVideo({ commit }, { id, video, token}) {
		return $http.put(`video/${id}`, video, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('UPDATE_VIDEO', response.data.data)
		})
	},
	deleteVideo({ commit }, {id, token}){
		return $http.delete(`video/${id}`, {
			headers : {
				'x-auth-token' : token
			}
		}).then(() => {
			commit('DELETE_VIDEO', id)	
		})
	},
	loadOrd({commit}, {token}) {
		return $http.get(`video/ord`, {
			headers : {
				'x-auth-token' : token
			}
		}).then((response) => {
			commit('SET_ORD', response.data.data[0].max)
		})
	},
	generateSignature({ commit }, { videoId, expirationTime, token }) {
		return $http.post('video/generate-signature', 
			{ videoId, expirationTime },
			{
				headers: {
					'x-auth-token': token
				}
			}
		).then(response => {
			return response.data.signature;
		})
	},
}

const mutations = {
    SET_VIDEOS : (state, videos) => {
		state.videos = videos
	},
	SET_VIDEO : (state, video) => {
		state.video = video
	},
	UPDATE_VIDEO: (state, video) => {
		state.video = video
		state.videos = state.videos.map(v => (v.id === video.id) ? video : v)
	},
	ADD_VIDEO : (state, video) => {
		state.videos.push(video)
	},
	DELETE_VIDEO : (state, id) => {
		state.videos = state.videos.filter(v => v.id !== id)
	},
	SET_ORD : (state, ord) => {
		state.ord = ord
	},
}

export default {
	state, getters, actions, mutations
}